import * as Sentry from "@sentry/sveltekit";
import { version } from "$app/environment";
import { building } from "$app/environment";
import { PUBLIC_SENTRY_DSN } from "$env/static/public";
async function initSentry() {
	Sentry.init({
		dsn: PUBLIC_SENTRY_DSN,
		tunnel: "/api/errors",
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		release: version,
		debug: false,
		integrations: [
			Sentry.browserProfilingIntegration(),
			Sentry.replayIntegration({
				maskAllText: true,
				blockAllMedia: true,
				mask: ["[data-mask]"],
				unmask: ["[data-unmask]"],
				maskAttributes: ["title", "aria-label"],
			}),
		],
		beforeSend(event) {
			if (process.env.NODE_ENV === "development") {
				return null;
			}

			if (event.request?.headers?.cookie) {
				delete event.request.headers.cookie;
			}

			if (event.user) {
				delete event.user.ip_address;
				delete event.user.email;
			}

			return event;
		},
		profilesSampleRate: 1.0,
		environment: process.env.NODE_ENV,
	});
}

if (process.env.NODE_ENV !== "development" && !building) {
	initSentry();
}

export const handleError = Sentry.handleErrorWithSentry();
